import React, { useState, useContext } from "react";
import { navigate } from "gatsby";
import { SessionContext } from '../session/sessionContext';
import { TranslationContext } from '../translation/translationContext';
import Screen from '../components/views/screen';
import Navbar from '../components/navbars/outsideNavbar';
import logo from '../assets/images/logo.svg';
import { outsideNavbarHeight } from '../assets/dimensions';
import SoftButton from '../components/buttons/softButton';
import ErrorDialog from '../components/dialogs/simpleDialog';

const styles = {
  loginContainer: {
    marginTop: outsideNavbarHeight + 60,
    paddingRight: 80,
    paddingLeft: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    "box-shadow": '4px 3px 19px 9px rgba(0, 0, 0, 0.25)',
    width: '40%'
  },
  logo: {
    marginTop: outsideNavbarHeight + 48,   
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  loginTitle: {
    alignSelf: 'center',
    fontFamily: 'Share',
    fontWeight: 700,
    fontSize: 20
  },
  loginButton: {
    width: '100%',
    marginTop: 24,
    marginBottom: 24,
  },
  link: {
    fontSize: 14,
    textDecoration: 'underline',
    marginLeft: 8,
    marginRight: 8
  },
  inputTitle: {
    marginBottom: 8,
    alignSelf: 'flex-start',
    fontFamily: 'Share',
    fontWeight: 700,
    fontSize: 16
  },
  input: {
    width: '90%',
    height: 40,
    marginBottom: 16,
    borderColor: '#B9B9B9',
    borderWidth: 1,
    fontSize: 16,
    paddingLeft: 16,
    paddingRight: 16
  }
};

const IndexPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { login, loginError, clearLoginError } = useContext(SessionContext);
  const { translate } = useContext(TranslationContext);

  const onPressLogin = async () => await login(email, password);

  const onPressForgotPassword = () => {
    console.log('pressed forgot');
  };

  const onPressNeedHelp = () => {
    console.log('pressed help');
  };

  return (
    <Screen>
      <Navbar currentTab={'/login'} />
      <ErrorDialog
        isOpen={!!loginError}
        close={clearLoginError}
        message={translate('loginError')}
      />
      <div style={styles.loginContainer}>
        <img src={logo} alt="logo" />
        <div style={styles.inputContainer}>
            <p style={styles.loginTitle}>{translate('log in')}</p>
            <p style={styles.inputTitle}>{translate('username')}</p>
            <input type="email" style={styles.input} value={email} onChange={(event) => setEmail(event.target.value)} />
            <p style={styles.inputTitle}>{translate('password')}</p>
            <input type="password" style={styles.input} value={password} onChange={(event) => setPassword(event.target.value)} />
            <SoftButton style={styles.loginButton} text={translate('log in')} onClick={onPressLogin} />
            {/* <div> // TODO: Use when available
                <a style={styles.link} onClick={onPressForgotPassword}>{translate('forgotPassword')}</a>
                <a style={styles.link} onClick={onPressNeedHelp}>{translate('loginHelp')}</a>
            </div> */}
        </div>
      </div>
    </Screen>
  )
}

export default IndexPage
