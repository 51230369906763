import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        marginTop: 48,
        marginBottom: 48,
        marginLeft: 60,
        marginRight: 60,
        fontWeight: 700,
        fontFamily: 'Roboto',
        fontSize: 20
    },
};


const SimpleDialog = ({ isOpen, close, message }) => {
    return (
    <Dialog open={isOpen} onBackdropClick={close}>
        <DialogContent style={styles.container}>
            <p style={styles.title}>{message}</p>
        </DialogContent>
    </Dialog>
)};

export default SimpleDialog;