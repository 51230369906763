import React from 'react';
import { PRICE_BLUE } from '../../assets/colors';

const styles = {
    container: {
        backgroundColor: PRICE_BLUE,
        borderWidth: 0,
        borderRadius: 20,   
        color: 'white',
        fontFamily: 'Share',
        fontWeight: 400,
        fontSize: 16,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32
    }
};

const SoftButton = ({ style, text, onClick }) => (
    <button style={{...styles.container, ...style}} onClick={onClick}>{text}</button>
);

export default SoftButton;